export function checkUnitSystem(system) {
  if (system === 'metric' || system === 'imperial') {
    return system;
  } else {
    throw new Error(`Got unknown unit_system : "${system}". Should be "metric" or "imperial"`);
  }
}

export const WIDGET_TEXTS = {
  CN: '我的尺寸是多少？',
  DE: 'Was ist meine Kleidergröße?',
  EN: 'What is my size?',
  ES: '¿Cual es mi talla?',
  FI: 'Mikä on minun kokoni?',
  FR: 'Quelle est ma taille ?',
  IT: "Qual'è la mia taglia ?",
  JP: '自分のサイズを探す',
  NL: 'Wat is mijn maat?',
  NO: 'Hva er min størrelse?',
  PT: 'Qual é o meu tamanho ?',
  SE: 'Vad är min storlek?',
  DA: 'Hvor høj er jeg?',
  US: 'What is my size?',
};

export function parseLanguage(language) {
  if (!language) throw new Error('No language argument');
  const splitted = language.split('-');
  let languageCode = splitted[0].toUpperCase();
  if (Object.keys(WIDGET_TEXTS).indexOf(languageCode) === -1) {
    console.warn(`[Fitle] Got unknown language "${language}". The plugin will default to english.`);
    languageCode = 'EN';
  }
  return languageCode;
}

// From a list of strings, get a list of characters with no repetition
function getListOfUniqueCharacters(stringList) {
  const uniqueCharactersObject = stringList
    .join('') // join all strings
    .split('') // get all characters
    .reduce((acc, cur) => ({ ...acc, [cur]: true }), {}); // remove character repetitions
  return Object.keys(uniqueCharactersObject);
}

/**
 * Check that the intersection of available sizes and the sizing chart is not empty.
 *  
 * If it's the case, return VALID
 * Else we do a check to see if the available sizes and the sizing chart sizes
 * have at least a character in common. If it's the case, return SOMEHOW_VALID (=good sizing chart but weird integration).
 * In this case, we cannot use available sizes as we have no way of knowing which sizes in the sizing chart are available.
 * Else return INVALID (=wrong sizing chart)
 * The second step allows us to validate edge cases such as:
 *   - XS/S in size selector vs XS and S in sizing chart (such as Wurth Modyf)
 *   - TOTO M in size selector vs M in sizing chart (such as Vandeputt)
 *   - M (FR 38) in size selector vs M in sizing chart (such as Ski Chic)
 * Ultimately, this function allows us to detect cases where a product is associated with a sizing chart
 * of a wrong format (S, M, L in size selector vs 36, 38, 40 in sizing chart for example)
 * 
 * @param {Array<{name: string}>} availableSizes - The sizes parsed from client specification.
 * @param {Array<{id: number, name: string, order: number}>} productSizes - The available sizes and the sizing chart sizes.
 * @returns {string} - Returns 'VALID', 'SOMEHOW_VALID', or 'INVALID' based on the validation logic.
 */
export function validateAvailableSizes(availableSizes, productSizes) {
  // We allow clients to not set available sizes.
  if (!availableSizes || !Array.isArray(availableSizes) || !availableSizes.length || availableSizes.every(size => !size.name)) return 'VALID';
  const sizingChartSizeNames = productSizes.map(size => size.name.toUpperCase());
  const availableSizeNames = availableSizes.map(size => size.name.toUpperCase());
  if (availableSizeNames.some(sizeName => sizingChartSizeNames.indexOf(sizeName) > -1)) {
    // A size in common. Show plugin and use available sizes
    return 'VALID';
  }
  const sizingChartSizeCharacters = getListOfUniqueCharacters(sizingChartSizeNames);
  const availableSizeCharacters = getListOfUniqueCharacters(availableSizeNames);
  if (sizingChartSizeCharacters.some(c => availableSizeCharacters.indexOf(c) > -1)) {
    // A character in common. Most likely weird integration. Don't set off alarm and show plugin but don't use available sizes
    return 'SOMEHOW_VALID';
  }
  // No character in common. Most likely wrong sizing chart association. Set off alarm and don't show plugin
  return 'INVALID';
}
